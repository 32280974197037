.contact-container {
    display: flex;
    justify-content: center;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.text, .inputs {
    display: flex;
    flex-direction: column;
}

.inputs {
    align-items: flex-end;
    margin-bottom: 4vh;
}

.text {
    margin-top: 4vh;
    align-items: flex-start;
}

.line {
    width: 100%;
}

.small-container {
    display: flex;
    align-items: center;
}

.small-container > img {
    margin-right: 1vw;
    height: 3vh;
}

/*Fancy inputs*/

.submit {
    margin-top: 2vh;
    border-radius: 2vh;
    background-color: grey;
    color: white;
    border: solid grey;
    font-size: 3vh;
    transition: .2s ease-out;
}

.submit:hover {
    color: grey;
    background-color: white;
    border: solid grey;
    transition: .2s ease-out;
}

.input {
    position: relative;
    margin: auto;
    width: 60vw;
}

.input > .label {
    position: absolute;
    top: 4vh;
    left: 0;
    font-size: 3vh;
    color: #9098A9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease;
}

.input > .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: #0077FF;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all .15s ease
}

.input > .inp {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 2vh 0;
    margin-top: 2vh;
    height: 2vh;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C8CCD4;
    background: none;
    border-radius: 0;
    color: #223254;
    transition: all .15s ease
}

.input > .ta {
    height: 20vmin;
    resize: none;

}

.input > .inp:hover {
    background: rgba(34, 50, 84, .03);
}

.input > .inp:not(:placeholder-shown) + span {
    color: #5A667F;
    transform: translateY(-26px) scale(.75)
}

.input > .inp:focus {
    background: none;
    outline: none
}

.input > .inp:focus + span {
    color: #0077FF;
    transform: translateY(-26px) scale(.75)
}

.input > .inp:focus + span + .border {
    transform: scaleX(1)
}