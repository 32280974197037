.about-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20vw;
    margin-right: 10vw;
    margin-bottom: 10vh;
}

.about-content > img {
    height: 25vh
}

.about-content > h1 {
    font-size: 5vh;
}

.about-content > p {
    font-size: 2vh;
    margin-bottom: -.1vh;
}

.about-content-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10vw;
    margin-right: 10vw;
}

.about-content-mobile > img {
    height: 25vh
}

.about-content-mobile > h1 {
    font-size: 5vh;
}

.about-content-mobile > p {
    font-size: 2vh;
    margin-bottom: -.1vh;
}