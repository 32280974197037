.details-frame-big {
    width: 50vw; /*1 per row*/
    margin-left: 0vw;
    margin-right: 0vw;
}

.details-frame-med {
  max-width: 33vw
}

.details-frame-square {
    max-width: 24vw; /*2 per row*/
    margin-right: 1.7vh;
}

.details-frame-small {
    max-width: 13.1vw; /*3 per row*/
    margin-right: 1.7vh;
}

.details-frame-tiny {
    max-width: 12vw; /*4 per row*/
    margin-right: 1vh;
}

.details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-container > h1 {
    font-size: 5vh;
}

.description {
    font-size: 2vh;
    white-space: pre-line;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
}

.details-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 50vw;
}

.details-video {
    width: 50vw !important
}

.details-grid-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 90vw;
}
