.footer {
    display: flex;
    justify-content: center;
    width: 10vw;
    margin-left: 45.5vw;
    position: fixed;
    bottom: 4vh;
}

.footer a {
    margin-right: 1vw;
    padding-top: 5vmin;
}

.footer a:focus {
    outline: none;
}

.img {
    height: 4vh;
}