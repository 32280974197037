.header {
    text-align: center;
    height: 14vh;
}

.logo {
    height: 15vh;
    padding-top: .5vh;
}

.logo-link:focus {
    outline: 0;
}

@media only screen and (min-width: 1025px) {
  .nav {
      height: 15vh;
      padding-top: 5vh;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
  }

    .nav-link-container {
        width: 8vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1vw;
        margin-left: 1vw;
    }
}

@media only screen and (max-width: 1024px) {
  .nav {
      height: 15vh;
      padding-top: 3vh;
      padding-bottom: 3vh;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
  }

    .nav-link-container {
        width: 15vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 6vw;
        margin-left: 6vw;
    }
}

.nav-link {
    display: inline-block;
    position: relative;
    font-size: 2vh;
    padding-top: 3vh;
    padding-right: 1vw;
    padding-left: 1vw;
    text-decoration: none;
    border-bottom: none;
}

.nav-link:focus {
    outline: 0
}

.nav-link:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 3vh;
    width: 100%;
    border-bottom: 1px solid;
    opacity: 0;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.nav-link:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sub-links-works {
    display: flex;
    text-align: center;
    width: 100%;
    flex-flow: column wrap;
    max-height: 8vh;
    justify-content: center;
    margin-left: -7vw;
}

.sub-links-prints {
    display: flex;
    text-align: center;
    width: 100%;
    flex-flow: column wrap;
    max-height: 8vh;
    justify-content: center;
    margin-left: -13vw;
}

.sub-links-shop {
    display: flex;
    text-align: center;
    width: 100%;
    flex-flow: column wrap;
    max-height: 12vh;
    justify-content: center;
    margin-left: -1vw;
}

.sub-link {
    padding-top: .7vh;
    padding-right: 0;
    padding-left: 0;
    text-decoration: none;
    border-bottom: none;
    font-size: 2vh;
    min-width: 6vw;
}

.sub-links {
  display: flex;
  flex-direction: column;
  align-items: center
}
