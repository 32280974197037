.video-carousel > .carousel {
    position: absolute !important;
    top: 0;
    z-index: -1;
    height: 100vh;
}

.carousel-pane {
    height: 100vh;
    pointer-events: none;
}

.carousel-pane > div {
    display: inline !important;
}

.carousel-pane > div > div > span > iframe {
    margin: 0 !important;
}

.control-dots {
    padding-left: 0;
}

.home-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5vh;
}

.home-link {
    color: white;
    text-decoration: none;
    font-size: 4vh;
    margin-top: 2.5vh;
}

.line {
    max-width: 50vw;
    color: white;
    margin-top: 2.5vh;

}
