.home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100vh;
    background-color: black;
    overflow: hidden;
}

.home-logo-container {
    position: relative;
    text-align: center;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.big-logo {
    cursor: pointer;
    max-width: 20vw;
}

@media only screen and (min-width: 1025px) { /*For desktop*/
    .logo-container:hover > p {
        opacity: 1;
        transition: .3s ease-in;
    }

    .logo-container:not(:hover) > p {
        opacity: 0;
        transition: .3s ease-in;
    }

    .home-text {
        font-size: 4vh;
        color: white;
        opacity: 0;
        position: absolute;
        margin-top: 25vh;
        pointer-events: none;
    }

    .bar-container {
        margin-top: 25vh;
        margin-right: 5vw;
        margin-left: 5vw;
    }

    .bar {
        height: 50vh;
    }
}

@media only screen and (max-width: 1024px) { /*For mobile*/
    .home-text {
        font-size: 6vw;
        color: white;
        position: absolute;
        margin-top: 50vh;
        pointer-events: none;
        opacity: 1;
    }

    .bar-container {
        margin-top: 37.5vh;
        margin-right: 5vw;
        margin-left: 5vw;
    }

    .bar {
        height: 25vh;
    }
}