.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-text {
    color: black;
}

.item-image {
    object-fit: cover;
    width: 21vw;
    height: 21vw
}

.item-image-mobile {
    object-fit: cover;
    width: 45vw;
    height: 45vw
}

.item-image-popup {
    margin-bottom: 1vh;
    width: 100%;
}

.popup-content {
    height: 90vh !important;
    width: 20vh !important;
    padding: 0 !important;
    background: none !important;
    border: none !important;
    display: flex;
    justify-content: center;
}

.modal-container {
    display: flex;
}

.modal {
    display: flex;
    justify-content: center;
    height: 90vh;
    width: 90vh;
}

.modal-image {
    height: 100%;
    max-width: 85vw;
    border: black solid 1vh;
}

.left-arrow {
    margin-right: 18vw;
    width: 15vh;
}

.right-arrow {
    margin-left: 18vw;
    width: 15vh;
}

.works-container {
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.works-container > a {
    text-decoration: none;
}

.works-carousel > .carousel{
    width: 35vw !important;
}

.slide > div {
    width: auto;
    margin: 0;
}

.works-carousel-mobile > .carousel {
    width: 90vw !important;
}
